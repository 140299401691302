<template>
  <div class="content" v-loading="Loading">
    <div class="confirm-msg" v-if="detailForm">
      <p class="title">提交信息</p>
      <ConfirmDetail :detailForm="detailForm" />
    </div>
    <div class="replay-list" v-if="detailForm">
      <p class="title">回复列表</p>
      <div class="confirm-content">
        <el-table :data="detailForm.reply" class="thead-light" stripe :height="
            tableHeight < 300 && detailForm.reply.length > 2
              ? '300'
              : tableHeight
          " style="width: 100%">
          <!-- 序号 -->
          <el-table-column width="100" type="index" :index="indexMethod" label="序号"></el-table-column>
          <!-- 操作 -->
          <el-table-column label="操作" width="150" fixed="right">
            <template slot-scope="scope">
              <el-button style="color: #3576ff" size="medium opacity-btn" @click="detailRow(scope.row)">查看
              </el-button>
            </template>
          </el-table-column>
          <template v-for="(th, index) in thead">
            <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label" :width="th.width || ''"
              :sortable="th.sortable" :key="index">
              <template slot-scope="scope">
                <!-- 说明 -->
                <span v-if="th.prop === 'name'">{{
                  `(${scope.row[th.prop]})回复`
                }}</span>
                <!-- 普通 -->
                <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
    </div>
    <ContentDialog v-model="openContentDialog" :replayContent="currentContent" />
  </div>
</template>

<script>
import { leaveMsgDetail } from "../api/leaveMsg-detail";
import ConfirmDetail from "../components/ConfirmDetail";
import ContentDialog from "../components/ContentDialog";
export default {
  data() {
    return {
      Loading: false,
      MsgId: this.$route.params.id,
      detailForm: null,
      thead: [
        { label: "回复内容", prop: "content_reply" },
        { label: "说明", prop: "name", width: 200 },
        { label: "回复时间", prop: "create_time", width: 300 },
      ],
      tableHeight: (this.tableHeight = window.innerHeight - 602), // 列表响应式高度
      openContentDialog: false, // 点击查看弹窗
      currentContent: "", //查看的内容
    };
  },
  methods: {
    // 获取留言详情
    getMsgDetail() {
      this.Loading = true;
      leaveMsgDetail({ id: this.MsgId })
        .then((res) => {
          this.detailForm = res.data;
          this.Loading = false;
        })
        .catch((err) => {
          this.Loading = false;
        });
    },
    // 序号计算
    indexMethod(index) {
      return index + 1;
    },
    // 查看回复内容
    detailRow(row) {
      this.currentContent = row.content_reply;
      this.openContentDialog = true;
    },
    // 监听窗口变化
    listenResize() {
      this.tableHeight = window.innerHeight - 602;
    },
  },
  created() {
    if (this.MsgId) {
      this.getMsgDetail();
    }
    window.addEventListener("resize", this.listenResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.listenResize);
  },
  components: {
    ConfirmDetail,
    ContentDialog,
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 0;
  min-height: calc(100vh - 190px);
  min-width: 800px;

  .title {
    height: 55px;
    line-height: 55px;
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    padding: 0 32px;
    border-bottom: 1px solid #e9e9e9;
  }

  .confirm-content {
    padding: 22px 32px;
  }

  .tool-tip {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
</style>