<template>
  <el-dialog title="查看" :visible.sync="openDialog" width="500px" class="dialog-vertical">
      <p><span>内容：</span>{{ replayContent }}</p>
      <template slot="footer">
        <el-button size="small" @click="openDialog = false">取消</el-button>
        <el-button size="small" type="primary" @click="openDialog = false">确定</el-button>
      </template>
  </el-dialog>
</template>

<script>
export default {
    model: {
        prop: "open",
        event: "closeDialog"
    },
    props: {
        open: {
            type: Boolean,
            default: false
        },
        replayContent: {
            type: String
        }
    },
    computed: {
        openDialog: {
            get() {
                return this.open
            },
            set(val) {
                this.$emit("closeDialog", val)
            }
        }
    }
}
</script>

<style>

</style>
