<template>
  <div class="confirm-content">
    <el-row>
        <el-col :span="6"><span class="label">会员名称</span>{{ detailForm.nickname }}</el-col>
        <el-col :span="12"><span class="label">架构/会内职务</span>{{ detailForm.title_name }}</el-col>
        <el-col :span="6"><span class="label">接收组织</span>{{ detailForm.organization_name }}</el-col>
    </el-row>
    <el-row>
        <el-col :span="6"><span class="label">状态
            </span><span class="dot" :class="detailForm.status_text === '已回复'?'green-dot':'yellow-dot'"></span>
            {{ detailForm.status_text }}</el-col>
        <el-col :span="18"><span class="label">提交时间</span>{{ detailForm.create_time }}</el-col>
    </el-row>
    <el-row>
        <el-col :span="24"><span class="label">标题</span>{{ detailForm.title }}</el-col>
    </el-row>
    <el-row>
        <el-col :span="24"><span class="label">内容</span>{{ detailForm.content }}</el-col>
    </el-row>
  </div>
</template>

<script>
export default {
    props: {
        detailForm: {
            type: [Object,Array]
        }
    }
}
</script>

<style lang="scss" scoped>
    .label {
        font-size: 16px;
        color: #000000;
        margin-right: 12px;
    }
    
    .dot {
        height: 6px;
        width: 6px;
        border-radius: 50%;
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle;
    }
    .el-col {
        padding-bottom: 36px;
        font-size: 14px;
        color: rgba(0,0,0,0.65);
        line-height: 16px;
        .green-dot {
            background-color: rgba(19, 206, 102, 1);
        }
        .yellow-dot {
            background-color: rgba(255, 203, 44, 1);
        }
    }
</style>